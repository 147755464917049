<script>
export default {
    name: "StarRatings",
    props: {
        rating: {
            type: [String, Number, ],
            default: 5,
        },
    },
    render (h) {
        const num = parseInt(this.rating);
        if (!num) {
            return h("span", "T.B.A.");
        }
        return h("span", "⭐".repeat(num));
    },
}
</script>

<style lang="scss" scoped>

</style>
